var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pagination"},[_c('div',{staticClass:"pagination__total"},[_vm._v(" Всего версий: "+_vm._s(_vm.pagination.totalItems)+" ")]),(_vm.pagination.totalItems > _vm.pagination.limit)?_c('ul',{staticClass:"pagination__list"},_vm._l((_vm.paginationArr),function(item,index){return _c('li',[(_vm.pagination.currentPage - 2 === item
                        && item !== 1
                        && _vm.paginationArr[index-1] !== item - 1
                        && _vm.totalPages > 10)?_c('span',{staticClass:"pagination__empty"},[_vm._v(" ... ")]):_vm._e(),_c('router-link',{class:{'pagination__active': item === _vm.pagination.currentPage},attrs:{"to":`/${_vm.link}/${item}`}},[_vm._v(" "+_vm._s(item)+" ")]),(_vm.pagination.currentPage + 2 === item
                        && item < _vm.totalPages
                        && _vm.totalPages > 10
                        && _vm.paginationArr[index+1] !== item + 1)?_c('span',{staticClass:"pagination__empty"},[_vm._v(" ... ")]):_vm._e()],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }