
    import Vue from 'vue';
    import RCP from '@/api/RCP.ts';

    import Field from '@/components/Field.vue';
    import SelectField from '@/components/SelectField.vue';
    import Pagination from '@/components/Pagination.vue';

    import { Template, TemplatesResponse } from '@/types/Template';
    import { ErrorResponse } from '@/types/Errors';

    export default Vue.extend({
        name: 'templates',
        data() {
          return {
              templates: [] as Template[],
              loading: false,
              error: false,
              request: false,
              searchAllow: false,
              search: '',
              delayTimer: 0,
              pagination: {
                  limit: 5,
                  currentPage: 1,
                  totalItems: 0
              },
              filter: {
                  sort: 'desc'
              },
              filters: {
                  sort: {
                      desc: 'Сначала последние',
                      asc: 'Сначала первые'
                  }
              }
          };
        },
        created() {
            const page = +this.$route.params.page || -1;

            this.getTemplates(page);
        },
        watch: {
            $route() {
                const page = +this.$route.params.page || -1;

                this.getTemplates(page, this.search);
            },
            search(value) {
                this.searchDelay(value);
            },
            filter: {
                handler() {
                    this.getTemplates(1, this.search);
                },
                deep: true
            }
        },
        methods: {
            getTemplates(page = -1, search: any = '') {
                if (!this.request) {
                    if (page === -1) {
                        page = this.pagination.currentPage;
                    }

                    this.request = true;
                    this.templates = [];
                    this.error = false;
                    this.loading = false;

                    const searchArr: any = [
                        {
                            name: ['like', `%${search}%`]
                        },
                        {
                            string_id: ['like', `%${search}%`]
                        },
                        {
                            description: ['like', `%${search}%`]
                        }
                    ];

                    if (!isNaN(search)) {
                        const id = {
                            id: ['==', +search]
                        };

                        searchArr.push(id);
                    }

                    const params: any = {
                        limit: this.pagination.limit,
                        offset: this.pagination.limit * (page - 1),
                        order: [
                            ['id', this.filter.sort]
                        ]
                    };

                    if (search) {
                        params.filter = {
                            $or: searchArr
                        };
                    }

                    RCP({
                        method: 'App.Template.list',
                        params,
                        id: 'getTemplates'
                    }).then((result: TemplatesResponse) => {
                        if (result.items) {
                            this.templates = result.items;
                            this.pagination.currentPage = page;
                            this.pagination.totalItems = result.total_count;

                            if (this.templates.length > 0 || search) {
                                this.searchAllow = true;
                            } else {
                                this.searchAllow = false;
                            }

                            if (search !== this.search) {
                                this.searchDelay(this.search);
                            }
                        } else {
                            this.error = true;
                        }

                        this.loading = true;
                        this.request = false;

                        if (this.pagination.totalItems > 0 &&
                            Math.ceil(this.pagination.totalItems / this.pagination.limit) < page) {
                            this.searchDelay(this.search, 0);
                        }
                    }).catch(() => {
                        this.error = true;
                        this.loading = true;
                        this.request = false;
                    });
                }
            },
            deleteTemplate(pk: number) {        
                    if (confirm('Вы уверены? : Данное действие невозможно будет отменить')) {
                        RCP({
                            method: 'App.Template.del',
                            params: {
                                pk
                            }
                        }).then((result: any) => {
                            if (result.count) {
                                this.getTemplates();
                            }
                        }).catch((error: ErrorResponse) => {
                            if (error.show) {
                                alert('Ошибка : ' + error.message);
                            }
                        });
                    }
                
            },
            searchDelay(value: any, time = 500) {
                clearTimeout(this.delayTimer);
                this.delayTimer = setTimeout(() => {
                    this.$router.push('/templates/');
                    this.getTemplates(1, value);
                }, time);
            }
        },
        components: {
            Field,
            SelectField,
            Pagination
        }
    });
