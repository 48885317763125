
    import Vue from 'vue';

    export default Vue.extend({
        name: 'Pagination',
        props: {
            pagination: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                paginationArr: [] as any,
                link: ''
            };
        },
        created() {
            this.checkLink();
            this.checkPagination();
        },
        watch: {
            pagination: {
                handler() {
                    this.checkPagination();
                },
                deep: true
            }
        },
        computed: {
            totalPages(): number {
                return Math.ceil(this.pagination.totalItems / this.pagination.limit);
            }
        },
        methods: {
            checkPagination() {
                let paginationArr = [];
                const curPage = this.pagination.currentPage;

                paginationArr.push(1);
                paginationArr.push(this.totalPages);

                if (this.totalPages <= 10) {
                    for (let i = 2; i < this.totalPages; i++) {
                        paginationArr.push(i);
                    }
                } else {

                    if (curPage > 3) {
                        const localArr = [];

                        if (curPage + 1 < this.totalPages) {
                            localArr.push(curPage + 1);
                        }

                        if (curPage + 2 < this.totalPages) {
                            localArr.push(curPage + 2);
                        }

                        paginationArr = [...paginationArr, curPage - 1, curPage - 2, ...localArr];

                        if (curPage !== this.totalPages) {
                            paginationArr.push(curPage);
                        }
                    } else if (curPage !== 1) {
                        paginationArr = [...paginationArr, curPage, curPage + 1, curPage + 2];
                    } else {
                        paginationArr = [...paginationArr, 2, 3];
                    }

                    for (let i = 10; i < this.totalPages; i += 10) {
                        if (paginationArr.indexOf(i) === -1) {
                            paginationArr.push(i);
                        }
                    }
                }

                this.paginationArr = paginationArr.sort((a, b) => {
                    return a - b;
                });
            },
            checkLink() {
                let pathArr = this.$route.path.split('/');
                pathArr = pathArr.filter((item:any) => {
                    return item;
                });

                // @ts-ignore
                if (!isNaN(pathArr[pathArr.length - 1])) {
                    pathArr.pop();
                }

                this.link = pathArr.join('/');
            }
        }
    });
